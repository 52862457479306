@import "../gutenberg_block";
.booking-block{
    padding-top: 0;
    margin-top: 2rem!important; //Overide default page spacing 
    padding-bottom: 2.5rem;
    @include breakpoint(S){
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
    @include breakpoint(L){
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
.theme--accent-lobster{
    .booking-location-select{
        .dropdown{
            &::after{
                filter: invert(1);
            }
        }
    }
}
@include breakpoint(S){
    .promo-code-field.dates-fields{
            width: 100%;
            border-bottom: solid 1px;
            margin-bottom: 1.5rem;
            @include color(border-color, 'body');
            .date-field{
                width: 100%;
            }
    }
    .booking-mask-modal-contain{
        width: 100%;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
}

.booking-location-select{
    .dropdown{
        &::after{
            filter: invert(1);
            @include breakpoint(S){
                right:0;
            }
        }
    }
}
.booking-mask {
   

    @include breakpoint(S) {
        width: 100%;
        max-width: 32rem;
    }
    .button.primary {
        margin: 0;
    }
    .field-label{
        margin-bottom: 0.9375rem;
        @include size-S;
    }
    .date-field-arrow{
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        top: -0.1rem;
        @include breakpoint(S){
            top: -1.5rem;
            left: -1.5rem;
        }
        svg{
            width: 100%;
            height: 100%;
            position:absolute;
            object-fit: contain;
            @include color(color, "body");
        }
    }
    .dropdown-arrow{
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        right: 1rem;
        bottom: 0.1rem;
        svg{
            width: 100%;
            height: 100%;
            position:absolute;
            object-fit: contain;
            @include color(color, "body");
        }
        @include breakpoint(S){
            right:0;
            bottom: 1.5rem;
        }
    }
}

.booking-location-select{
    border-right: 1px solid;
    @include color(border-color, 'body');
    position: relative;
    align-items: flex-end;
    margin-bottom: 0;
    @include breakpoint(S) {
        border-right: 0;
        border-bottom: 1px solid;
        @include color(border-color, 'body');
    }
    .dropdown{ 
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
        width: 11.75rem;
        @include breakpoint(S){
            margin-bottom: 1.7142857142857142rem;
            width: 100%;
        }
        select{
            height: 100%;
            z-index: 1;
            @include color(color, 'body');
            background: transparent;
            border: 0;
            padding: 0;
            margin: 0;
            font-family: $font-stack-headings;
            font-weight: 500;
            font-size: 14px;
        }

    }
}

.dates-fields.promo-code-field{
    border-right: unset;
}

.dates-fields {
    border-right: 1px solid;
    @include color(border-color, 'body');
    position: relative;
    align-items: flex-end;

    @include breakpoint(S) {
        border-right: 0;
        border-bottom: 1px solid;
        @include color(border-color, 'body');
    }
  
    .date-field {
        padding: 0.21875rem 1rem;
        position: relative;
        @include breakpoint(S){
            padding-left: 0;
            padding-top: 1.5rem;
            padding-bottom: 1.7142857142857142rem;
            width: 7rem;
        }

        span.h5 {
            font-size: 14px;
        }

        .hide{
            visibility: hidden;
            opacity: 0;
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            color: transparent;
            background: transparent;
            border: 0;
            padding: 0;
            margin: 0;
            opacity: 0;
        }
        svg {
            margin-right: 0.75rem;
            @include color(color, 'body');
        }
        input.promo-code-field{
            position: relative;
            @include color(color, 'body');
            opacity: 1;
            width: 8rem;
            visibility: 1;
        }
       
    }
    .datepicker-trigger {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}

.rooms-guests-fields {
    position: relative;
    @include breakpoint(S){
        display: flex;
        flex-direction: column;
    }

    .rooms-guests {
        padding: 0.21875rem 1rem;
        min-width: 14rem;
        cursor: pointer;
        position: relative;
        border-right: 1px solid;
        .padding-right{
            padding-right: 3rem;
        }

        span.h5 {
            font-size: 14px;
        }
        
        @include color(border-color, 'body');
        @include breakpoint(S){
            width: 100%;
            border-right:unset;
            border-bottom: 1px solid;
            @include color(border-color, 'body');
            padding-left: 0;
            padding-top: 1.5rem;
            padding-bottom: 1.7142857142857142rem;

        }

        svg {
            margin-right: 0.75rem;
            @include color(color, 'body');
            @include breakpoint(S){
                margin-right: 0;
            }
        }
    }
}

.banner-block {
    .booking-mask {
        margin-top: 4.5rem;
    }
}

.rooms-guests-select {
    // some css for this is in _base-styles.scss to stop it flashing up on page load
    @extend %transition;
    z-index: 5;

    @include breakpoint(XS) {
        left: auto;
        right: 0;
    }
    .select-inner {
        box-shadow: 0px 4px 8px rgba($color-body, 0.15);
        padding: 1.5rem;
        width: 100%;
        border-radius: 2px;
        box-shadow: 4px 8px 24px rgba(0, 10, 44, 0.075);
        @include color(background-color, 'background');
    

        &.no-padding {
            padding: 0;
        }
        footer {
            padding-top: 1.5rem;
            border-top: 1px solid;
            @include color(border-color, 'body-10');

            a {
                margin-bottom: 0;
                @include color(color, 'background');

            }
        }
        .selector-value {
            width: 3rem;
        }
    }
    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 7px 7px;
        border-color: transparent transparent #ffffff transparent;
        top: -6px;
        left: 1rem;

        @include breakpoint(XS) {
            left: auto;
            right: 1rem;
        }
    }
    &.active {
        top: calc(100% + 1.5rem);
        visibility: visible;
        opacity: 1;
    }
}

.force-mobile-mask {
    width: 100%;
    
    @media only screen and (max-width: 1023px) {
        display: flex;
        justify-content: center;
    }
    .booking-mask {
        width: 100%;
        flex-direction: column;
        max-width: 20rem;

        .dates-fields {
            border-right: 0;
            border-bottom: 1px solid;
            @include color(border-color, 'body');

            .date-field {
                width: 50%;
            }
        }
    }
}

.banner-portrait {
    padding-bottom: 0;
}

.booking-mask-modal-contain{
    position: relative;
    @include breakpoint(S){
        overflow: scroll;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .rooms-guests-fields{
        position: unset;
    }
    .rooms-guests-select.active{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        &::before{
            display: none;
        }
        @include breakpoint(S){
            position: relative;
        }
    }
    .select-inner{
        max-width: 30rem;
        margin: 0 auto;
        &::after{
            content: "";
            position: fixed;
            z-index: -1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
        }
        @include breakpoint(S){
            width: 100%;
            &::after{
                display: none;
            }
        }
    }
}