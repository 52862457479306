//Configure your project's core colour palette here
// ================================================

$color-body: #020B20;
$color-background: #FFF;
$color-background-alt: #FFF9E0;
$color-accent-primary: #F88F65;

//Additional Brand Colours
$color-accent-sea: #4D7EE6;
$color-accent-aqua: #4DDFE6;
$color-accent-goldfish: #F88F65;
$color-accent-sunshine: #FFDB50;
$color-accent-sugarcane: #4DBD8F;
$color-accent-lobster: #F15E7A;
$color-accent-bougain: #AF4DAF;
$color-accent-midnight-swim: #5E5B93;
$color-accent-coral: #F585A4; 

$color-success: #00A05F;
$color-warning: #FFCB05;
$color-error: #EB1941;

// Define your standard colour variables here
// ================================================

$color-vars: (
  body: $color-body,
  body-75: rgba($color-body, 0.75),
  body-50: rgba($color-body, 0.50),
  body-25: rgba($color-body, 0.25),
  body-10: rgba($color-body, 0.10),
  body-05: rgba($color-body, 0.05),
  body-01: rgba($color-body, 0.01),

  background: $color-background,
  background-75: rgba($color-background, 0.75),
  background-50: rgba($color-background, 0.5),
  background-25: rgba($color-background, 0.25),
  background-10: rgba($color-background, 0.10),
  background-05: rgba($color-background, 0.05),
  background-01: rgba($color-background, 0.01),

  background-alt: $color-background-alt,

  accent-primary: $color-accent-primary,
  accent-primary-lightest: mix($color-accent-primary, #FFF, 25%),
  accent-primary-lighter: mix($color-accent-primary, #FFF, 50%),
  accent-primary-light: mix($color-accent-primary, #FFF, 87.5%),
  accent-primary-dark: mix($color-accent-primary, #000, 87.5%),
  accent-primary-darker: mix($color-accent-primary, #000, 50%),
  accent-primary-darkest: mix($color-accent-primary, #000, 25%),

  accent-sea: $color-accent-sea,
  accent-sea-lightest: mix($color-accent-sea, #FFF, 25%),
  accent-sea-lighter: mix($color-accent-sea, #FFF, 50%),
  accent-sea-light: mix($color-accent-sea, #FFF, 87.5%),
  accent-sea-dark: mix($color-accent-sea, #000, 87.5%),
  accent-sea-darker: mix($color-accent-sea, #000, 50%),
  accent-sea-darkest: mix($color-accent-sea, #000, 25%),

  accent-aqua: $color-accent-aqua,
  accent-aqua-lightest: mix($color-accent-aqua, #FFF, 25%),
  accent-aqua-lighter: mix($color-accent-aqua, #FFF, 50%),
  accent-aqua-light: mix($color-accent-aqua, #FFF, 87.5%),
  accent-aqua-dark: mix($color-accent-aqua, #000, 87.5%),
  accent-aqua-darker: mix($color-accent-aqua, #000, 50%),
  accent-aqua-darkest: mix($color-accent-aqua, #000, 25%),

  accent-goldfish: $color-accent-goldfish,
  accent-goldfish-lightest: mix($color-accent-goldfish, #FFF, 25%),
  accent-goldfish-lighter: mix($color-accent-goldfish, #FFF, 50%),
  accent-goldfish-light: mix($color-accent-goldfish, #FFF, 87.5%),
  accent-goldfish-dark: mix($color-accent-goldfish, #000, 87.5%),
  accent-goldfish-darker: mix($color-accent-goldfish, #000, 50%),
  accent-goldfish-darkest: mix($color-accent-goldfish, #000, 25%),

  accent-sunshine: $color-accent-sunshine,
  accent-sunshine-lightest: mix($color-accent-sunshine, #FFF, 25%),
  accent-sunshine-lighter: mix($color-accent-sunshine, #FFF, 50%),
  accent-sunshine-light: mix($color-accent-sunshine, #FFF, 87.5%),
  accent-sunshine-dark: mix($color-accent-sunshine, #000, 87.5%),
  accent-sunshine-darker: mix($color-accent-sunshine, #000, 50%),
  accent-sunshine-darkest: mix($color-accent-sunshine, #000, 25%),

  accent-sugarcane: $color-accent-sugarcane,
  accent-sugarcane-lightest: mix($color-accent-sugarcane, #FFF, 25%),
  accent-sugarcane-lighter: mix($color-accent-sugarcane, #FFF, 50%),
  accent-sugarcane-light: mix($color-accent-sugarcane, #FFF, 87.5%),
  accent-sugarcane-dark: mix($color-accent-sugarcane, #000, 87.5%),
  accent-sugarcane-darker: mix($color-accent-sugarcane, #000, 50%),
  accent-sugarcane-darkest: mix($color-accent-sugarcane, #000, 25%),

  accent-lobster: $color-accent-lobster,
  accent-lobster-lightest: mix($color-accent-lobster, #FFF, 25%),
  accent-lobster-lighter: mix($color-accent-lobster, #FFF, 50%),
  accent-lobster-light: mix($color-accent-lobster, #FFF, 87.5%),
  accent-lobster-dark: mix($color-accent-lobster, #000, 87.5%),
  accent-lobster-darker: mix($color-accent-lobster, #000, 50%),
  accent-lobster-darkest: mix($color-accent-lobster, #000, 25%),

  accent-bougain: $color-accent-bougain,
  accent-bougain-lightest: mix($color-accent-bougain, #FFF, 25%),
  accent-bougain-lighter: mix($color-accent-bougain, #FFF, 50%),
  accent-bougain-light: mix($color-accent-bougain, #FFF, 87.5%),
  accent-bougain-dark: mix($color-accent-bougain, #000, 87.5%),
  accent-bougain-darker: mix($color-accent-bougain, #000, 50%),
  accent-bougain-darkest: mix($color-accent-bougain, #000, 25%),

  accent-midnight-swim: $color-accent-midnight-swim,
  accent-midnight-swim-lightest: mix($color-accent-midnight-swim, #FFF, 25%),
  accent-midnight-swim-lighter: mix($color-accent-midnight-swim, #FFF, 50%),
  accent-midnight-swim-light: mix($color-accent-midnight-swim, #FFF, 87.5%),
  accent-midnight-swim-dark: mix($color-accent-midnight-swim, #000, 87.5%),
  accent-midnight-swim-darker: mix($color-accent-midnight-swim, #000, 50%),
  accent-midnight-swim-darkest: mix($color-accent-midnight-swim, #000, 25%),

  accent-coral: $color-accent-coral,
  accent-coral-lightest: mix($color-accent-coral, #FFF, 25%),
  accent-coral-lighter: mix($color-accent-coral, #FFF, 50%),
  accent-coral-light: mix($color-accent-coral, #FFF, 87.5%),
  accent-coral-dark: mix($color-accent-coral, #000, 87.5%),
  accent-coral-darker: mix($color-accent-coral, #000, 50%),
  accent-coral-darkest: mix($color-accent-coral, #000, 25%),

  accent-reverse: $color-background,
  accent-reverse-alt: $color-body,

  success: $color-success,
  success-lightest: mix($color-success, #FFF, 25%),
  success-lighter: mix($color-success, #FFF, 50%),
  success-light: mix($color-success, #FFF, 87.5%),
  success-dark: mix($color-success, #000, 87.5%),
  success-darker: mix($color-success, #000, 50%),
  success-darkest: mix($color-success, #000, 25%),

  warning: $color-warning,
  warning-lightest: mix($color-warning, #FFF, 25%),
  warning-lighter: mix($color-warning, #FFF, 50%),
  warning-light: mix($color-warning, #FFF, 87.5%),
  warning-dark: mix($color-warning, #000, 87.5%),
  warning-darker: mix($color-warning, #000, 50%),
  warning-darkest: mix($color-warning, #000, 25%),

  error: $color-error,
  error-lightest: mix($color-error, #FFF, 25%),
  error-lighter: mix($color-error, #FFF, 50%),
  error-light: mix($color-error, #FFF, 87.5%),
  error-dark: mix($color-error, #000, 87.5%),
  error-darker: mix($color-error, #000, 50%),
  error-darkest: mix($color-error, #000, 25%),
);

// A mixin that gives you an appropriate fallback for browsers that don't support CSS variables (basically just IE)
// Usage example:
//  @include color(background-color, 'accent-primary', #333);
// outputs:
//  background-color: #333;
//  background-color: var(--accent-primary, #333);
//
// Tip: You don't need to include a fallback variable for most cases – you only need to use it when your desired
//      IE fallback isn't the standard default value (e.g. setting text colour over an image background)

@mixin color($property, $color, $fallback: null) {
    @if $fallback {
        #{$property}: #{$fallback};
        #{$property}: var(--color-#{$color}, #{$fallback});
    } @else {
        #{$property}: map-deep-get($color-vars, $color);
        #{$property}: var(--color-#{$color}, map-get($color-vars, $color));
    }
}